import React, {Component, useEffect, useState} from "react";
import {ChartDisplay, SearchForm, ShareBox} from '../components/index'
import * as styles from '../styles/index.module.scss'
import Layout from '../components/layout'
import {GlobalDispatchContext, GlobalStateContext} from '../context/context'
import {Link, navigate} from "gatsby"
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";

const EmployerPage = () => {

  const dispatch = React.useContext(GlobalDispatchContext);
  let state = null;
  let current_employer = null;
  const fauxState = React.useContext(GlobalStateContext);
  if (fauxState != null){
	state = fauxState;
	current_employer = state.current_employer
  }
  let employer_id = "";

  if (typeof window !== `undefined`){
	const params = new URLSearchParams(window.location.search) 
	employer_id = params.get('employer_id') 
  }
  let resetToolTip = {

		a: false,
		b: false,
		c: false,
		d: false,
		e: false,
		f: false,
		
	
  }
  const [toolTip, setToolTip] = useState(resetToolTip);


  useEffect(() => {
	if (state != null) {
		if (state.current_employer != null ){
			fetch("https://lead5050.staging.parachute.net/api/?aasddfaaa&employer_id="+ renderSimple("employerID"))
			.then(response => response.json())
			.then(data => {

				dispatch({type: "UPDATE_DETAILED_DATA", payload: data})
			}) 
			}
		if (state.data.length == 1){ 
			

			fetch("https://lead5050.staging.parachute.net/api/?latest23")
			.then(response => response.json())
			.then(data => {
			  dispatch({type: "UPDATE_DATA", payload: data})
			  if (employer_id != null && employer_id != ""){
				  for (var t of data){ 
					  if (t.employerID == employer_id){

						dispatch({type: "UPDATE_CURRENT_EMPLOYER", payload: t})

					  }
				  }

			  }
			}) 


		}
	}
 

  }, [current_employer ])


const getSize = () =>{
	if (state != null) {
	if (state.data != null){
	return state.data.length
	}
	}
}
  const renderSimple = (key) =>{
	if (state != null) {

		if(state!= null && state.current_employer != null ){
			return state.current_employer[key];
		}
	}
  }

  const renderDetailed = (key,year) =>{

	var originalkey = key;
	if (state != null) {
	if(year == null){
		year = "2024";

	}
	if(state!= null && state.detailed_data){
		if (state.detailed_data[year] != null){

			if (key == "percentage_males_in_organisation" ){
				key = "percentage_females_in_organisation" 
			}


		if (state.detailed_data[year][key] != null){
			var data = state.detailed_data[year][key];	
			data = data.replace("%","");
			if (originalkey == "percentage_males_in_organisation" ){
				data = 100 - data;
				data = data.toFixed(1);
			}

		
		return 	data;
		}else{
			return "-";
		}
	}
	}}
  }

  const showYear = (reducer, data)  =>{

	dispatch({type: reducer, payload: !data})
  }

  const setToolTipVisiblity = (toolTip) =>{
	setToolTip(toolTip)
  }

  const renderDifferenceFixed = (number1, number2, inverse, fixed) => {

if (fixed == null){
	fixed = 1;

}
	  var direction = "down";
	  if (number1 != null && typeof number1 == 'string'){
	  number1 = number1.replace("st","");
	  number1 = number1.replace("nd","");
	  number1 = number1.replace("rd","");
	  number1 = number1.replace("%","");
	  number1 = number1.replace(",","");
	  number1 = number1.replace("th","");
	  number1 = number1.replace("%","");

	  }
	  if (number1 != null && typeof number2 == 'string'){

	  number2 = number2.replace("st","");
	  number2 = number2.replace("rd","");
	  number2 = number2.replace("nd","");
	  number2 = number2.replace("%","");
	  number2 = number2.replace(",","");
	  number2 = number2.replace("th","");
	  number2 = number2.replace("%","");

	  }

	  if (number1 < number2){
		  direction = "up";

	  }

	  if (number1 == number2){
		  direction = "equals";

	  }

	  var calc = (number1 - number2).toFixed(fixed);

	  if (isNaN(calc)){
		  return "N/A";

	  }
	  if (calc > 0){
		 // calc = "+" + calc;

	  }
	 
	  if (inverse == true){
		  if (direction == "up"){
			  direction = "down";

		  }else if (direction == "down"){
			  direction = "up";
		  }

	  }
	  calc = calc.replace("-","");
	  return (
		  <>
		  <i className={direction}></i>
		  <span>{calc}</span>
		  </>
		  )
  }

  const removePercentage = (number) => {
	  if (number != null && number != ""){
		  return number.replace("%","", number);

	  }
  }
  const renderDifferencePercentage = (number1, number2) => {
	  var direction = "down";
	  if (number1 < number2){
		  direction = "up";

	  }
	  return (
		  <>
		  	<i class={direction}></i>
		  	<span>{number1 - number2}</span>
		  </>
		  )
	}	

const renderImage = () =>{
	return "https://lead5050.staging.parachute.net/image?employer_id=" + employer_id;
		
}
const updateCompareYear = (year,data) => {
	dispatch({type: year, payload: data})
}

  const renderCompareYears = (reducer,data) =>{
	return(
		data.map((el, i) => (
		<li onClick={() => updateCompareYear(reducer,el)}>{el}</li>
		)
		)			
	)
  }
  if (state != null){

  return (
	  <>
	<Helmet>
    <title>{renderDetailed("name")}</title>
	<meta property="og:image" content={renderImage()} />
  
  </Helmet>
    <Layout pageTitle="Home Page">
		<div className="container">
			<div className="row justify-content-center align-items-center">
				<div className="col-lg-9">
					<div className="user-content text-center block-padding-medium">
						<h1>Gender pay gap</h1>
					</div>
				</div>
			</div>
		</div>


<div className="container employer-data-container">
	<div className="row align-items-start justify-content-between">
	<div className="col-lg-6">
		<ChartDisplay/>
	</div>
		<div className="col-lg-5">
			<div className="top-employers common-background">
				<header>
					<h3>{renderDetailed("name")}</h3>

				</header>
			
				<div className="user-content">
					<div className="key-detail">
					<p><strong>Location: </strong> {renderDetailed("region")}</p>

						<p><strong>Employee Size:</strong> {renderDetailed("employees")}</p>
						<p><strong>Sector: </strong> {renderDetailed("sector")}</p>

					</div>
					<div className="scores">					
					<div className="score">
						<h4>Score</h4>
						<h3>{removePercentage(renderDetailed("lead5050gpgindex"))}</h3>
						<p>out of 100</p>
					</div>
					<div className="score">
						<h4>Overall Rank</h4> 
						<h3>{renderDetailed("rank")}</h3>

						<p>out of {getSize(state.data)}</p>
					</div>
				</div>
			</div>


		</div>

	</div>
</div>
</div>


<div className="compare-years">
	<div className="container">
		<div className="row">
			<div className="col-lg-12">
				<header>
				<h3>Compare Years</h3> 
				<div className="compare-year ">
					<h3 className="no-arrow">{state.compare_year1_selected}
				</h3></div><h3>Vs</h3><div className="compare-year">
				<h3  onClick={() => showYear("UPDATE_YEAR2_SHOW", state.compare_year2_show)} >{state.compare_year2_selected}
				<ul className={state.compare_year2_show ? styles.active : null}>{renderCompareYears("UPDATE_YEAR2_SELECTION", state.compare_year2)}</ul>				
				</h3>
				</div> 

				</header>
				<p>The 'Gap' shows the percentage difference in hourly pay between males and females as submitted to the government (positive values favour males).<br></br>



	The 'Rank' shows where the company lies nationwide out of ALL submitted data for that indicator.<br></br>


The Decile shows which tenth the company falls into nationally for that indicator.<br></br>

</p>
			</div>
		</div>
	</div>
</div>


<div className="container">
	<div className="row align-items-end">
		<div className="col-lg-3">
			<div className="comparison-table">
				<ul>
					<li data-target="#mean-pay-gap" >Mean pay gap
					<i onClick={() => setToolTipVisiblity({resetToolTip , a: !toolTip.a})} className="tooltip">?<div style={{display: toolTip.a && 'block' }} className="tooltip">
					The Mean Pay Gap is the percentage difference between the Mean of all male hourly pay and the Mean of all female hourly pay (positive values favour males).
			</div></i>
					
					</li>
					<li data-target="#median-pay-gap">Median pay gap
					<i onClick={() =>  setToolTipVisiblity({resetToolTip , b: !toolTip.b})} className="tooltip">?<div style={{display: toolTip.b && 'block' }} className="tooltip">
					The Median Pay Gap is the percentage difference between the Median of all male hourly pay and the Median of all female hourly pay (positive values favour males).			</div></i>
						</li>
					<li data-target="#mean-bonus-gap">Quarters Score 	<i onClick={() => setToolTipVisiblity({resetToolTip , c: !toolTip.c})} className="tooltip">?<div style={{display: toolTip.c && 'block' }} className="tooltip">
					The Quarters Score is a twofold calculation; one looking at how close the company is to a 50/50 gender split across the company quarters of earners and then the second looking at how close the overall companies gender split is spread across the company quarters of earners.
								</div></i>
						</li>

				</ul>
			</div>
		</div>
		<div className="col-lg-9">
			<div className="comparison-table-container active" id="mean-pay-gap" >
				<div className="row align-items-end">
					<div className="col-lg-6">
						<div className="data-table">
						<header>{state.compare_year1_selected}</header>
							<div className="table-content">
								<table>
									<tr>
										<th>Gap</th>
										<th>Rank</th>
										<th>Decile</th>
									</tr>
									<tr class="mobile-heading" data-heading="Mean Pay Gap">
										<td>{renderDetailed("mean_gpg",state.compare_year1_selected)}</td>
										<td>{renderDetailed("mean_pay_gap_rank",state.compare_year1_selected)}</td>
										<td>{renderDetailed("decile_mean_pay_gap",state.compare_year1_selected)}</td>

									</tr>
									<tr class="mobile-heading" data-heading="Median Pay Gap">
									<td>{renderDetailed("median_gpg",state.compare_year1_selected)}</td>
										<td>{renderDetailed("median_pay_gap_rank",state.compare_year1_selected)}</td>
										<td>{renderDetailed("decile_median_pay_gap",state.compare_year1_selected)}</td>
									</tr>
									<tr class="mobile-heading" data-heading="Quarters Score">
									<td></td>
										<td>{renderDetailed("quartiles_rank",state.compare_year1_selected)}</td>
										<td>{renderDetailed("decile_quarters",state.compare_year1_selected)}</td>
									</tr>	
																															
								</table>
							</div>

						</div>
						
					</div>

					<div className="col-lg-6">
						<div className="data-table">
						<header>{state.compare_year2_selected}</header>
							<div className="table-content">
								<table> 
								<tr>
										<th>Gap</th>
										<th>Rank</th>
										<th>Decile</th>
									</tr>
									<tr class="mobile-heading" data-heading="Mean Pay Gap">
										<td>{renderDetailed("mean_gpg",state.compare_year2_selected)}</td>
										<td>{renderDetailed("mean_pay_gap_rank",state.compare_year2_selected)}</td>
										<td>{renderDetailed("decile_mean_pay_gap",state.compare_year2_selected)}</td>

									</tr>
									<tr class="mobile-heading" data-heading="Median Pay Gap">
									<td>{renderDetailed("median_gpg",state.compare_year2_selected)}</td>
										<td>{renderDetailed("median_pay_gap_rank",state.compare_year2_selected)}</td>
										<td>{renderDetailed("decile_median_pay_gap",state.compare_year2_selected)}</td>
									</tr>
									<tr class="mobile-heading" data-heading="Quarters Score">
									<td></td>
									<td>{renderDetailed("quartiles_rank",state.compare_year2_selected)}</td>
										<td>{renderDetailed("decile_quarters",state.compare_year2_selected)}</td>
									</tr>	
																																			
								</table>
							</div>
						</div>
					</div>
					{/* <div className="col-lg-4">
						<div className="data-table">
							<header>Comparison</header>
							<div className="table-content">
								<table>
									<tr>
										<th>Gap</th>
										<th>Rank</th>
										<th>Decile</th> 
									</tr>
									<tr>
										<td>{renderDifferenceFixed(renderDetailed("mean_gpg",state.compare_year1_selected), renderDetailed("mean_gpg",state.compare_year2_selected), false)}</td>
										<td>{renderDifferenceFixed(renderDetailed("mean_pay_gap_rank",state.compare_year1_selected), renderDetailed("mean_pay_gap_rank",state.compare_year2_selected),true,0)}</td>
										<td>{renderDifferenceFixed(renderDetailed("decile_mean_pay_gap",state.compare_year1_selected), renderDetailed("decile_mean_pay_gap",state.compare_year2_selected),false,0)}</td>
									</tr>
									<tr>
									<td>{renderDifferenceFixed(renderDetailed("median_gpg",state.compare_year1_selected), renderDetailed("median_gpg",state.compare_year2_selected))}</td>
										<td>{renderDifferenceFixed(renderDetailed("median_pay_gap_rank",state.compare_year1_selected), renderDetailed("median_pay_gap_rank",state.compare_year2_selected),false,0)}</td>
										<td>{renderDifferenceFixed(renderDetailed("decile_median_pay_gap",state.compare_year1_selected), renderDetailed("decile_median_pay_gap",state.compare_year2_selected),false,0)}</td>
									</tr>
									<tr>
									<td>-</td>
									<td>{renderDifferenceFixed(renderDetailed("quartiles_rank",state.compare_year1_selected), renderDetailed("quartiles_rank",state.compare_year2_selected),false,0)}</td>
									<td>{renderDifferenceFixed(renderDetailed("decile_quarters",state.compare_year1_selected), renderDetailed("decile_quarters",state.compare_year2_selected),false,0)}</td>
									</tr>
																																		
								</table>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	</div>
</div>
<div className="score-line" >
	<div className="container">
	<div className="row align-items-center">
			<div className="col-lg-3">
			<h4>Lead5050 Equity Index <i onClick={() => setToolTipVisiblity({resetToolTip , d: !toolTip.d})} className="tooltip">?<div style={{display: toolTip.d && 'block' }} className="tooltip">
			The Equity Index takes all the factors supplied on the government website and calculates a percentage score and then ranks ALL the companies across the UK.

			</div></i> </h4>
			</div>
			<div className="col-lg-9">
				<div className="row">
				<div className="col-lg-6">
				<div class="mobile-year">
						{state.compare_year1_selected}
					</div>
					<div className="stand-out-line-container years">

						 <div className="stand-out-group years">
							<div className="heading"><h4>Score</h4></div>
							<div className="stand-out-line">{renderDetailed("lead5050gpgindex",state.compare_year1_selected)}</div>

							</div>
							<div className="stand-out-group years">

							<div className="heading"><h4>Rank</h4></div>
							<div className="stand-out-line">{renderDetailed("lead_5050_gpg_rank",state.compare_year1_selected)}</div>

							</div>
						</div>					
					</div>
					<div className="col-lg-6">
					<div class="mobile-year">
						{state.compare_year2_selected}
					</div>
					<div className="stand-out-line-container years">
						 <div className="stand-out-group years">
							<div className="heading"><h4>Score</h4></div>
							<div className="stand-out-line">{renderDetailed("lead5050gpgindex",state.compare_year2_selected)}</div>
							</div>
							<div className="stand-out-group years">

							<div className="heading"><h4>Rank</h4></div>
							<div className="stand-out-line">{renderDetailed("lead_5050_gpg_rank",state.compare_year2_selected)}</div>
							</div>
						</div>					
					</div> 
					{/* <div className="col-lg-4">
						<div className="stand-out-line-container years">
							<div className="stand-out-group years">
								<div className="heading"><h4>Score</h4></div>
								<div className="stand-out-line">{renderDifferenceFixed(renderDetailed("lead5050gpgindex",state.compare_year1_selected), renderDetailed("lead5050gpgindex",state.compare_year2_selected), true)}%</div>
								</div>
								<div className="stand-out-group years">

								<div className="heading"><h4>Rank</h4></div>
								<div className="stand-out-line">{renderDifferenceFixed(renderDetailed("lead_5050_gpg_rank",state.compare_year1_selected), renderDetailed("lead_5050_gpg_rank",state.compare_year2_selected),false,0)}</div>
								</div>
							</div>					
						</div> */}
					</div>
				</div>
			</div>
		</div>
	</div>
<div className="container" id="quarters">
	<div className="row">
		<div className="col-lg-12">
		<h3 style={{"paddingBottom":"0px"}}>Quarters Splits</h3>
			<p>This splits the company into quarters of earners between male and female to show the gender split across the company.</p>
		</div>
	</div>
	<div className="row align-items-end">
		<div className="col-lg-3">
			<div className="comparison-table" style={{"marginBottom" :"5px"}}>
				<ul>
				<li data-target="#mean-pay-gap" >Whole company </li>

					<li data-target="#mean-pay-gap" >Upper </li>
					<li data-target="#median-pay-gap">Upper Middle</li>
					<li data-target="#mean-bonus-gap">Lower Middle </li>
										<li data-target="#mean-bonus-gap">Lowest</li>

				</ul>
			</div>
		</div>
		<div className="col-lg-9">
			<div className="comparison-table-container active" id="mean-pay-gap" >
				<div className="row">
					<div className="col-lg-6">
					<div class="mobile-year">
						{state.compare_year1_selected}
					</div>
						<div className="stand-out-line-container years">

							<div className="stand-out-group years">
							<div className="heading"><h4>Male</h4></div>
							<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("percentage_males_in_organisation",state.compare_year1_selected)}%</div>
							</div>

							<div className="stand-out-group years">
							<div className="heading"><h4>Female</h4></div>
							<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("percentage_females_in_organisation",state.compare_year1_selected)}%</div>

							</div>
							<div className="stand-out-group years">
							<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("highest_quarter_male",state.compare_year1_selected)}%</div>

							</div>

							<div className="stand-out-group years">
							<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("highest_quarter_female",state.compare_year1_selected)}%</div>

							</div> 
									<div className="stand-out-group years">
							<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("upper_middle_quarter_male",state.compare_year1_selected)}%</div>

							</div>

							<div className="stand-out-group years">
							<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("upper_middle_quarter_female",state.compare_year1_selected)}%</div>

							</div>
							
									<div className="stand-out-group years">
							<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("lower_middle_quarter_male",state.compare_year1_selected)}%</div>

							</div>

							<div className="stand-out-group years">
							<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("lower_middle_quarter_female",state.compare_year1_selected)}%</div>

							</div>
								
										<div className="stand-out-group years">
							<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("lowest_quarter_male",state.compare_year1_selected)}%</div>

							</div>

							<div className="stand-out-group years">
							<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("lowest_quarter_female",state.compare_year1_selected)}%</div>

							</div>
							
						

						</div>
						
					</div>

					<div className="col-lg-6">
					<div class="mobile-year">
						{state.compare_year2_selected}
					</div>
					<div className="stand-out-line-container years">


<div className="stand-out-group years">
<div className="heading"><h4>Male</h4></div>
<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("percentage_males_in_organisation",state.compare_year2_selected)}%</div>

</div>

<div className="stand-out-group years">
<div className="heading"><h4>Female</h4></div>
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("percentage_females_in_organisation",state.compare_year2_selected)}%</div>

</div>
<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("upper_middle_quarter_male",state.compare_year2_selected)}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("upper_middle_quarter_female",state.compare_year2_selected)}%</div>

</div>
<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("upper_middle_quarter_male",state.compare_year2_selected)}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("upper_middle_quarter_female",state.compare_year2_selected)}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("lower_middle_quarter_male",state.compare_year2_selected)}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("lower_middle_quarter_female",state.compare_year2_selected)}%</div>

</div>

	<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i>{renderDetailed("lowest_quarter_male",state.compare_year2_selected)}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i>{renderDetailed("lowest_quarter_female",state.compare_year2_selected)}%</div>

</div>



</div>
						
					</div>


					<div className="col-lg-4" style={{display:"none"}}>
					<div className="stand-out-line-container years">


<div className="stand-out-group years">
<div className="heading"><h4>Male</h4></div>
<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i> {renderDifferenceFixed(renderDetailed("percentage_males_in_organisation",state.compare_year1_selected), renderDetailed("percentage_males_in_organisation",state.compare_year2_selected))}%</div>

</div>

<div className="stand-out-group years">
<div className="heading"><h4>Female</h4></div>
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i> {renderDifferenceFixed(renderDetailed("percentage_females_in_organisation",state.compare_year1_selected), renderDetailed("percentage_females_in_organisation",state.compare_year2_selected))}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i> {renderDifferenceFixed(renderDetailed("upper_middle_quarter_male",state.compare_year1_selected), renderDetailed("upper_middle_quarter_male",state.compare_year2_selected))}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i> {renderDifferenceFixed(renderDetailed("upper_middle_quarter_female",state.compare_year1_selected), renderDetailed("upper_middle_quarter_female",state.compare_year2_selected))}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i> {renderDifferenceFixed(renderDetailed("lower_middle_quarter_male",state.compare_year1_selected), renderDetailed("lower_middle_quarter_male",state.compare_year2_selected))}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i> {renderDifferenceFixed(renderDetailed("lower_middle_quarter_female",state.compare_year1_selected), renderDetailed("lower_middle_quarter_female",state.compare_year2_selected))}%</div>

</div>

	<div className="stand-out-group years">
	<div className="stand-out-line"><i className="fa fa-male" aria-hidden="true"></i> {renderDifferenceFixed(renderDetailed("lowest_quarter_male",state.compare_year1_selected), renderDetailed("lowest_quarter_male",state.compare_year2_selected))}%</div>

</div>

<div className="stand-out-group years">
<div className="stand-out-line"><i className="fa fa-female" aria-hidden="true"></i> {renderDifferenceFixed(renderDetailed("lowest_quarter_female",state.compare_year1_selected), renderDetailed("lowest_quarter_female",state.compare_year2_selected))}%</div>

</div>



</div>
					</div>
				</div>
			</div>
		</div>
	</div>


</div>

<br/><br/>

<div className="score-line">
	<div className="container">

	<div className="row">
		<div className="col-lg-12">
			<h3 style={{"paddingBottom":"0px"}}>Bonuses</h3>
			<p>This shows the percentage of each gender receiving bonuses and the average gaps between the gender bonuses (positive values favour males).</p>
		</div>
	</div>



	<div className="row align-items-center">
			<div className="col-lg-3">
			<h4>Who gets a bonus </h4>
		
			</div>
			<div className="col-lg-9">
				<div className="row">
				<div className="col-lg-6">
				<div class="mobile-year">
						{state.compare_year1_selected}
					</div>
					<div className="stand-out-line-container years">

						 <div className="stand-out-group years">
							<div className="heading"><h4>Male</h4></div>
							<div className="stand-out-line">{renderDetailed("%_males_receiving_bonus",state.compare_year1_selected)}%</div>

							</div>
							<div className="stand-out-group years">

							<div className="heading"><h4>Female</h4></div>
							<div className="stand-out-line">{renderDetailed("%_of_females_receiving_a_bonus",state.compare_year1_selected)}%</div>

							</div>
						</div>					
					</div>
					<div className="col-lg-6">
					<div class="mobile-year">
						{state.compare_year2_selected}
					</div>
					<div className="stand-out-line-container years">
						 <div className="stand-out-group years">
							<div className="heading"><h4>Male</h4></div>
							<div className="stand-out-line">{renderDetailed("%_males_receiving_bonus",state.compare_year2_selected)}%</div>
							</div>
							<div className="stand-out-group years">

							<div className="heading"><h4>Female</h4></div>
							<div className="stand-out-line">{renderDetailed("%_of_females_receiving_a_bonus",state.compare_year2_selected)}%</div>
							</div>
						</div>					
					</div> 
					{/* <div className="col-lg-4">
						<div className="stand-out-line-container years">
							<div className="stand-out-group years">
								<div className="heading"><h4>Male</h4></div>
								<div className="stand-out-line">{renderDifferenceFixed(renderDetailed("%_males_receiving_bonus",state.compare_year1_selected), renderDetailed("%_males_receiving_bonus",state.compare_year2_selected),false)}%</div>
								</div>
								<div className="stand-out-group years">

								<div className="heading"><h4>Female</h4></div>
								<div className="stand-out-line">{renderDifferenceFixed(renderDetailed("%_of_females_receiving_a_bonus",state.compare_year1_selected), renderDetailed("%_of_females_receiving_a_bonus",state.compare_year2_selected),false)}%</div>
								</div>
							</div>					
						</div> */}
					</div>
				</div>
			</div>
		</div>
	</div>
<div className="score-line">
	<div className="container">
	<div className="row align-items-center">
			<div className="col-lg-3">
			<h4>Bonus Gap <i onClick={() => setToolTipVisiblity({resetToolTip , e: !toolTip.e})} className="tooltip">?<div style={{display: toolTip.e && 'block' }} className="tooltip">
			The Bonus Gap is the percentage difference between the average male bonus and the average female bonus (positive values favour males).
			</div></i> </h4>
			</div>
			<div className="col-lg-9">
				<div className="row">
				<div className="col-lg-6"> 
				<div class="mobile-year">
						{state.compare_year1_selected}
					</div>
					<div className="stand-out-line-container years">

						 <div className="stand-out-group years">
							<div className="heading"><h4>Mean</h4></div>
							<div className="stand-out-line">{renderDetailed("mean_bonus_gap",state.compare_year1_selected)}%</div>

							</div>
							<div className="stand-out-group years">

							<div className="heading"><h4>Median</h4></div>
							<div className="stand-out-line">{renderDetailed("median_bonus_gap",state.compare_year1_selected)}%</div>

							</div>
						</div>					
					</div>
					<div className="col-lg-6">
					<div class="mobile-year">
						{state.compare_year2_selected}
					</div>
					<div className="stand-out-line-container years">
						 <div className="stand-out-group years">
						 <div className="heading"><h4>Mean</h4></div>
							<div className="stand-out-line">{renderDetailed("mean_bonus_gap",state.compare_year2_selected)}%</div>
							</div>
							<div className="stand-out-group years">

							<div className="heading"><h4>Median</h4></div>
							<div className="stand-out-line">{renderDetailed("median_bonus_gap",state.compare_year2_selected)}%</div>
							</div>
						</div>					
					</div> 
					{/* <div className="col-lg-4">
						<div className="stand-out-line-container years">
							<div className="stand-out-group years">
							<div className="heading"><h4>Mean</h4></div>
								<div className="stand-out-line">{renderDifferenceFixed(renderDetailed("mean_bonus_gap",state.compare_year1_selected), renderDetailed("mean_bonus_gap",state.compare_year2_selected))}%</div>
								</div>
								<div className="stand-out-group years">

								<div className="heading"><h4>Median</h4></div>
								<div className="stand-out-line">{renderDifferenceFixed(renderDetailed("median_bonus_gap",state.compare_year1_selected), renderDetailed("median_bonus_gap",state.compare_year2_selected))}%</div>
								</div>
							</div>					
						</div> */}
					</div>
				</div>
			</div>
		</div>
	</div>
	<ShareBox/>
	<SearchForm/>
			
    </Layout>
	</>
  ) 
}
}
export default EmployerPage
